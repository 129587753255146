<template>
  <v-container>
    <v-layout row wrap justify-space-between>
      <v-flex xs12 sm6 md6 lg4 class="text-right">
        <v-text-field
          v-model="search"
          dense
          solo
          :placeholder="this.$t('search licenses')"
        ></v-text-field> </v-flex
      ><v-spacer></v-spacer>
    </v-layout>

    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table :headers="headers" :loading="loading" :items="licenses">
          <template v-slot:[`item.ownerId`]="{ item }">
            <span class="caption">{{ fetchOwnerName(item.id) }}</span>
          </template>

          <template v-slot:[`item.packageId`]="{ item }">
            <span class="caption">{{ fetchPackageName(item.id) }}</span>
          </template>

          <template v-slot:[`item.startDate`]="{ item }">
            <span class="caption">{{ DateFormat(item.startDate) }}</span>
          </template>

          <template v-slot:[`item.expiryDate`]="{ item }">
            <span class="caption">{{ DateFormat(item.expiryDate) }}</span>
          </template>

          <template v-slot:[`item.isValid`]="{ item }">
            <span
              v-if="item.isValid"
              class="caption success lighten-5 px-2 py-1 green--text text--darken-4"
            >
              {{ $t("valid") }}
            </span>
            <span
              v-else
              class="caption red lighten-5 px-2 py-1 error--text text--darken-4"
            >
              {{ $t("expired") }}
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              v-for="button in icons"
              :key="button.icon"
              small
              :color="$store.state.secondaryColor"
              class="mx-1"
              style="cursor: pointer"
              @click="goTo(item.id)"
              >{{ button.icon }}</v-icon
            >

            <v-icon
              small
              class="mx-1"
              color="red"
              style="cursor: pointer"
              @click="deleteItem(item)"
              >fas fa-trash</v-icon
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">{{ $t("delete license") }}</v-card-title>
        <v-card-text
          >{{ $t("you are about to delete a license belonging to")
          }}<b>{{ ownerName }}</b> <b>{{ packageName }}</b>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="deleteLicense(deteledItemId)"
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card dark height="30">
        <v-card-text class="py-2">
          <v-progress-linear
            height="10"
            :v-model="progressValue"
            :buffer-value="progressValue"
            stream
            striped
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackSuccess"
      bottom
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("license successfully deleted") }}</span>

      <v-btn icon color="success darken-3" @click="snackSuccess = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      bottom
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("failed to delete license. Please try again later") }}</span>

      <v-btn icon color="red darken-3" @click="snackError = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import format from "date-fns/format";
export default {
  data: () => ({
    licenses: [],
    owners: [],
    ownerName: "",
    packages: [],
    packageName: "",
    deteledItemId: "",
    progressValue: 10,
    deleteDialog: false,
    dialog: false,
    loading: true,
    search: "",
    icons: [{ icon: "fas fa-edit", route: "update" }],

    snackSuccess: false,
    snackError: false,
  }),

  computed: {
    filterLicenses() {
      return this.licenses.filter((val) => {
        return (
          this.fetchOwnerName(val.id)
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          this.fetchPackageName(val.id)
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          this.DateFormat(val.startDate)
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          this.DateFormat(val.expiryDate)
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      });
    },
    headers() {
      return [
        {
          text: this.$t("owner"),
          value: "ownerId",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("package"),
          value: "packageId",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("issued date"),
          value: "startDate",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("expiry date"),
          value: "expiryDate",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("validity"),
          value: "isValid",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("action"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
      ];
    },
  },

  created() {
    this.getLicenses();
  },

  methods: {
    getLicenses() {
      this.licenses = [];
      this.owners = [];
      this.packages = [];

      db.collection("licenses")
        .orderBy("startDate", "desc")
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            this.licenses.push({
              id: snaps.id,
              ...snaps.data(),
            });
            this.getOwners(snaps.id, snaps.data().ownerId);
            this.getPackages(snaps.id, snaps.data().packageId);
          });
          this.loading = false;
        });
    },

    getOwners(licenseId, ownerId) {
      db.collection("owners")
        .doc(ownerId)
        .get()
        .then((snaps) => {
          let fullname =
            snaps.data().names.first +
            " " +
            snaps.data().names.middle.substr(0, 1) +
            ". " +
            snaps.data().names.last;

          this.owners.push({
            license: licenseId,
            name: fullname,
          });
        });
    },

    getPackages(licenseId, packageId) {
      db.collection("packages")
        .doc(packageId)
        .get()
        .then((snaps) => {
          this.packages.push({
            license: licenseId,
            package: snaps.data().name,
          });
        });
    },

    fetchOwnerName(id) {
      var index = this.owners.findIndex((x) => x.license == id);

      return this.owners[index].name;
    },

    fetchPackageName(id) {
      var index = this.packages.findIndex((x) => x.license == id);
      return this.packages[index].package;
    },

    deleteItem(item) {
      this.dialog = true;
      this.deteledItemId = item.id;
      this.ownerName = this.fetchOwnerName(item.id);
      this.packageName = this.fetchPackageName(item.id);
    },
    //delete license function
    deleteLicense(data) {
      this.dialog = false;
      this.deleteDialog = true;
      this.progressValue = 50;

      this.progressValue = 80;
      db.collection("licenses")
        .doc(data)
        .delete()
        .then(() => {
          this.progressValue = 100;
          this.deleteDialog = false;
          this.snackSuccess = true;

          this.getLicenses();
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
          this.snackError = true;
        });
    },

    DateFormat(data) {
      return data != null ? format(data.toDate(), "eee dd-MMM-yyyy HH:mm") : "";
    },

    goTo(data) {
      this.$router.push({ name: "UpdateLicense", params: { licenseId: data } });
    },
  },
};
</script>

<style></style>
